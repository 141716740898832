.Slideshow {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background: black;
  overflow: hidden;

  img {
    -webkit-user-drag: none;
    object-fit: contain;
    background-color: black;
  }
}